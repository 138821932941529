import React from 'react';
import { Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import clsx from 'clsx';
import BackgroundImage from 'gatsby-background-image';
import { styleConfig } from '../../config/config';
import RouteLink from './RouteLink';

const ContentModuleMagazineBlocks: React.FunctionComponent<ContentModuleMagazineBlocksProps> = (
  props
) => {
  const { rows } = props;

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      position: 'relative',
    },
    arrow: {
      verticalAlign: 'middle',
      display: 'inline-flex',
      width: 42,
      height: 42,
      transition: '0.3s ease-in-out all',
      opacity: 0,
      marginLeft: 10,

      [theme.breakpoints.down('lg')]: {
        opacity: 1,
      },
    },
    image: {
      transition: 'all .8s cubic-bezier(.76,.53,.22,.86)',
      zIndex: 0,
      height: '100%',
      willChange: 'transform',
      transform: 'scale(1)',

      [theme.breakpoints.down('md')]: {
        height: '35rem',
      },

      [theme.breakpoints.up('xl')]: {
        height: '50rem',
      },
    },
    content: {
      position: 'absolute',
      top: 0,
      overflow: 'hidden',
      zIndex: 555,
      display: 'block',
      padding: '3rem 2rem',
      height: '28.1rem',
      width: '70%',

      [theme.breakpoints.down('lg')]: {
        height: '35rem',
        padding: '2rem',
        width: '100%',
      },

      [theme.breakpoints.up('xl')]: {
        height: '50rem',
        padding: '4rem',
        width: '100%',
      },
    },
    imageRoot: {
      height: '35rem',

      [theme.breakpoints.up('xl')]: {
        height: '50rem',
      },
    },
    model: {
      position: 'relative',
      overflow: 'hidden',
      transition: '0.3s ease-in-out all',
      background: 'transparent',
      willChange: 'transform',
      transform: 'scale(1)',

      '&:before': {
        transition: '0.3s ease-in-out all',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.30)',
        content: "''",
        zIndex: 1,
      },

      '&:hover': {
        '& $arrow': {
          transform: 'translate3d(15px, 0, 0)',
          willChange: 'transform',

          opacity: 1,
        },

        '&:before': {
          background: 'rgba(0, 0, 0, 0.50)',
        },

        '& $image': {
          transform: 'scale(1.1)',
        },

        '& $subtitle': {
          [theme.breakpoints.up('lg')]: {
            bottom: '4rem',
          },
        },
      },
    },
    textDark: {
      color: styleConfig.color.text.darkgrey,
    },
    textWhite: {
      color: styleConfig.color.text.white,
    },
    textPrimary: {
      color: styleConfig.color.text.gold,
    },
    alignRight: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row-reverse',
      textAlign: 'right',
      '& li': {
        textAlign: 'right',
      },
    },
    alignSubtitle: {
      flexDirection: 'column',
    },
    title: {
      display: 'block',
      margin: '0',
      fontFamily: 'Bai Jamjuree',
      fontSize: '3.5rem!important',
      fontWeight: 600,

      [theme.breakpoints.down('sm')]: {
        fontSize: '2.4rem!important',
      },
    },
    subtitle: {
      fontSize: 20,
      fontWeight: 600,

      '&:hover': {
        '& a': {
          '&:hover': {
            textDecoration: 'underline',
          },
          '.arrow': {
            opacity: 1,
          },
        },
      },
    },
  }));

  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      {rows &&
        rows.map((row) => {
          return (
            row &&
            row.cols.map((col, index) => {
              const image = getImage(col.image.localFile);
              const bgImage = convertToBgImage(image);

              return (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  className={`row-${index}`}
                  md={6}
                  lg={
                    (row.cols.length === 3 && 4) ||
                    (row.cols.length === 2 && 6) ||
                    (row.cols.length === 1 && 12) ||
                    12
                  }
                  xl={
                    (row.cols.length === 3 && 4) ||
                    (row.cols.length === 2 && 6) ||
                    (row.cols.length === 1 && 12) ||
                    12
                  }
                  key={`cm-magazineBlock-${index}`}
                  style={{ background: styleConfig.color.bg.darkgrey }}
                >
                  {col && col.link && col.link.url && (
                    <RouteLink href={col && col.link && col.link.url}>
                      <div className={classes.model}>
                        {col.image && col.image.localFile && (
                          <div className={classes.imageRoot}>
                            <BackgroundImage
                              fadeIn={false}
                              className={classes.image}
                              alt={col.image.alt_text}
                              {...bgImage}
                              style={{ height: '100%' }}
                            />
                          </div>
                        )}

                        <div className={classes.content}>
                          {col && (
                            <React.Fragment>
                              <h3
                                className={clsx([
                                  col.title_colour === 'gold' && classes.textPrimary,
                                  col.title_colour === 'black' && classes.textDark,
                                  col.title_colour === 'white' && classes.textWhite,
                                  col.text_align === 'right' && classes.alignRight,
                                  col.subtitle && classes.alignSubtitle,
                                  classes.title,
                                ])}
                              >
                                {col.title}

                                {col.link && col.link.url && (
                                  <ArrowRightAltIcon color="primary" className={classes.arrow} />
                                )}
                              </h3>

                              {col.subtitle && (
                                <Typography
                                  className={clsx(
                                    col.subtext_colour === 'gold' && classes.textPrimary,
                                    col.subtext_colour === 'black' && classes.textDark,
                                    col.subtext_colour === 'white' && classes.textWhite,
                                    col.text_align === 'right' && classes.alignRight,
                                    col.subtitle && classes.alignSubtitle,
                                    classes.subtitle
                                  )}
                                >
                                  {col.subtitle}
                                </Typography>
                              )}
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </RouteLink>
                  )}
                </Grid>
              );
            })
          );
        })}
    </Grid>
  );
};

interface ContentModuleMagazineBlocksProps {
  rows: {
    cols: {
      contents: string;
      subtext_colour: string;
      text_align: string;
      title_colour: string;
      subtitle: string;
      link: {
        url: string;
      };
      title: string;
      description: string;
      image: {
        alt_text: string;
        localFile: any;
      };
    }[];
  }[];
}

export default ContentModuleMagazineBlocks;
